/* Form.css */

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

/* Update the styles for input elements */
input,
select {
  width: 100%;
  padding: 8px;
  margin-bottom: 2px;
  box-sizing: border-box;
}

/* Apply the color theme to the button */
button.submitButtonMaterial {
  background-color: #265682;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

/* Style for form submit button on hover */
button.submitButtonMaterial:hover {
  background-color: #1b4161;
}

.textStartWithEliteContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  padding: 4%;
}

.textStartWithEliteContainer .EliteMatchesTexts {
  font-family: "Roboto", sans-serif;
  /* Fallback to sans-serif font if Roboto is not available */
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  /* Adjusted font size */
  line-height: 24px;
  color: #2c2c2c;
}

.textStartWithEliteContainer .verifiedRisteyTexts {
  color: #424242;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 550;
  line-height: 18px;
}

.textStartWithEliteContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  padding: 0%;
  margin-bottom: 2px;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.buttonBox .submitButtonMaterial {
  width: 1000%;
}

.error {
  color: red;
  font-weight: normal;
  font-size: 10px;
  line-height: 0;
  display: inline;
  margin-left: 10px;
}

.thankYouMessage {
  margin-top: 10px;
}

.form {
  transform: translateY(20px) rotate(0deg);
  /* background-color: red; */
}

input,
select {
  margin-top: 10px !important;
}