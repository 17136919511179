/* Header.css */

div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  /* Adjust width as needed */
}

/* img {
  max-width: 200px;
  margin-bottom: 7px;
  margin-left: 55px;
} */
img {
  max-width: 175px;
  height: 94px;
  /* margin-bottom: 7px; */
  /* margin-left: 55px; */
}

/* Style for the company name */
.companyNameHans {
  color: #265682;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-left: 5px;
  text-align: center;
}