/* App.css */

.hansFormMainContainer {
  /* background-image: url("images/background.png"); */
  align-items: center;
  /* margin-left: 300px; */
  /* justify-content: space-between; */
  background-position: 0.1rem 7.5rem;
  border: 20px solid white;
  /* Adjust border color as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 0;
  /* Remove default body margin */
  padding: 0;
  /* Remove default body padding */
  width: 450px;
  /* Full-width */
  /* height: 00px; */
  /* Add any additional styles as needed */
}

.hansFormMainContainer {
  background-color: rgb(247, 196, 140);
  box-shadow: -10px 10px 30px rgb(107, 78, 47) !important;
  transform: translateY(0) rotate(0deg);
  margin-bottom: 30px !important;
  border-radius: 2.9% !important;
  border-color: rgb(247, 196, 140);
}



.flower-border:before {
  content: "✿✿✿✿✿✿✿✿✿✿✿✿✿✿✿✿✿✿";
  color: rgb(207, 9, 9);
}


#root {
  /* height: 100vh; */
  background-image: url('../public/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  background-position: 50% top;
}